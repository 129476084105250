<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Add Visitor</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                            <div class="input-group custom-input-group">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                        label="label" placeholder="Select" disabled
                                        class="multiselect-custom custom-title-multiselect" :canClear="false"
                                        :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                        :canDeselect="false" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <input type="text" v-model="searchmobile" class="form-control" id="formMobileNoInput"
                                        placeholder="Enter Mobile No." maxlength="10" @keypress="onlyNumber"
                                        autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                                @click="searchvoterbtn(searchmobile)" :disabled="searchmobile.length != 10">
                                <span v-if="!btnloader">Search</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-for="msg in searchMobileResult" :key="msg">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="voter-exit-box-outer mb-3">
                            <div class="row">
                                <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                                    <div class="customer-label-group">
                                        <div class="d-flex align-items-center">
                                            <div :class="msg.ak29 == 3
                                                ? 'border-voter-user' : msg.ak29 == 2 ? 'border-voter-karyakartas'
                                                    : 'border-voter-only'
                                                ">
                                                <img v-if="(msg.ak90 == null || msg.ak90 == '') && (msg.ak33 == null || msg.ak33 == '')"
                                                    src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                                <img v-if="(msg.ak90 != null || msg.ak90 != 'NULL' || msg.ak90 != '') && (msg.ak33 == null || msg.ak33 == 'NULL' || msg.ak33 == '')"
                                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + msg.ak90"
                                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                                <img v-if="msg.ak33 != null && msg.ak33 != ''"
                                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + msg.ak33"
                                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                                <img v-if="msg.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg"
                                                    alt="" title="Voter" width="16" />
                                                <img v-if="msg.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg"
                                                    alt="" title="Karyakartas" width="16" />
                                                <img v-if="msg.ak29 == 3" src="/assets/images/icon/customers-tab-icon.svg"
                                                    alt="" title="User" width="16" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msg.ak5 ? msg.ak5 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Mobile No.</label>
                                                <div class="from-label-value">
                                                    +91-{{ msg.ak24 }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Lok Sabha</label>
                                                <div class="from-label-value">
                                                    {{ msg.ak63 ? msg.ak63 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Email Id</label>
                                                <div class="from-label-value">
                                                    {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Birth Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(msg.ak18) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Vidhan Sabha</label>
                                                <div class="from-label-value">
                                                    {{ msg.ak61 ? msg.ak61 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Gender</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                                        msg.ak22
                                                        ? msg.ak22 == 1
                                                            ? "Male"
                                                            : msg.ak22 == 2
                                                                ? "Female"
                                                                : "Others"
                                                        : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Designation</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msg.ak84 ? msg.ak84 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-1 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12 text-end">
                                            <button type="button" title="Add Visitor Detail"
                                                class="btn custom-outline-view-btn" @click="voterAddVisitModalOpen(msg)">
                                                <i class="pi pi-plus call-btn-color"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- search voter alert modal start here -->
    <div class="modal-mask" v-if="usersearchalertmodals">
        <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Alert</h5>
                    <button type="button" class="btn-close" @click="closeSearchAlertModal()"></button>
                </div>
                <div class="modal-body text-center alert-body-bg">
                    <div class="voter-alert-group">
                        <div class="mb-4">+91 {{ this.ak24 }} Mobile Number has been not Registered in voter list!</div>
                        <div>If you want to add new voter Please go to Voters > Add Voter Page</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-center">
                        <button type="button" class="btn modal-bulk-next-btn" @click="redirecttoaddvoter()"
                            :style="{ width: '135px' }">
                            <span v-if="!createuserloader">Add Voter</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="createuserloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- search voter alert modal end here -->
    <!-- Add Visitor Registration Form start here -->
    <div class="modal-mask" v-if="addvisitorModalOpen">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Visitor</h5>
                    <button type="button" class="btn-close" @click="voterAddVisitModalClose()"></button>
                </div>
                <!-- Create Voter Stepper First start here -->
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                                <div class="input-group custom-input-group">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <Multiselect v-model="add.countrycode" :options="countryCodeList" :searchable="true"
                                            label="label" placeholder="Select" disabled
                                            class="multiselect-custom custom-title-multiselect" :canClear="false"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            :canDeselect="false" />
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <input type="text" v-model="add.primobile" class="form-control"
                                            id="formMobileNoInput" disabled placeholder="Enter Mobile No." maxlength="10"
                                            @keypress="onlyNumber" autocomplete="off" />
                                    </div>
                                </div>
                                <div class="custom-error" v-if="v$.add.primobile.$error">
                                    {{ v$.add.primobile.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                                <label for="formLastNameInput" class="form-label">Visitor Name<span
                                        class="text-danger">*</span></label>
                                <input type="text" v-model="add.fullname" class="form-control text-capitalize"
                                    id="formLastNameInput" placeholder="Enter Visitor Name" autocomplete="off"
                                    maxlength="20" @keypress="isLetterWithSpace($event)" disabled />
                                <div class="custom-error" v-if="v$.add.fullname.$error">
                                    {{ v$.add.fullname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formmessageInput" class="form-label">Visit Reason<span
                                        class="text-danger">*</span></label>
                                <textarea type="text" v-model="add.visitreason" class="form-control" id="formmessageInput"
                                    placeholder="Enter Visit Reason" autocomplete="off" rows="3" maxlength="250"></textarea>
                                <div class="custom-error" v-if="v$.add.visitreason.$error">
                                    {{ v$.add.visitreason.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-custom-group">
                                <label class="form-custom-label">Visit Date & Time<span class="text-danger">*</span>
                                </label>
                                <div class="input-group">
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar v-model="add.visitdate" :showIcon="true" class="custom-ultima-calendar"
                                            placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                                            :monthNavigator="true" :yearRange="'1990:' + new Date().getFullYear()"
                                            appendTo="body" :yearNavigator="true" />
                                        <span class="custom-error" v-if="v$.add.visitdate.$error">
                                            {{ v$.add.visitdate.$errors[0].$message }}
                                        </span>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar id="time12" placeholder="HH:MM" v-model="add.visittime" :timeOnly="true"
                                            :manualInput="false" hourFormat="12"
                                            class="custom-ultima-calendar custom-time-outer" appendTo="body" />
                                        <span class="custom-error" v-if="v$.add.visittime.$error">
                                            {{ v$.add.visittime.$errors[0].$message }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-^ col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Karyakartas Name<span
                                        class="text-danger">*</span></label>
                                <Multiselect v-model="add.karyakartasname" :options="karyakartasList" :searchable="true"
                                    label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                                    :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                                <div class="custom-error" v-if="v$.add.karyakartasname.$error">
                                    {{ v$.add.karyakartasname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="CreateVisitDetailbtn()">
                            <span v-if="!addvoterregloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addvoterregloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Visitor Registration Form end here -->
</template>
<script>
import { required, helpers, numeric, minLength, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            btnloader: false,
            searchMobileResult: [],
            usersearchalertmodals: false,
            createuserloader: false,
            addvisitorModalOpen: false,
            addvoterregloader: false,
            add: {
                countrycode: { value: 101, label: "+91" },
                fullname: '',
                primobile: '',
                visitreason: '',
                visitdate: '',
                visittime: '',
                karyakartasname: '',
            },
            karyakartasList: [],
            addRowId: '',
            client_info: '',
            voterstorageimgpath: '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            add: {
                primobile: {
                    required: helpers.withMessage('Please enter Mobile No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
                        "Mobile no should be min 10 digit",
                        minLength(10)
                    ), maxLength: maxLength(10)
                },
                fullname: { required: helpers.withMessage('Please enter name', required) },
                visitreason: { required: helpers.withMessage('Please enter visit reason', required) },
                visitdate: { required: helpers.withMessage('Please select visit date', required) },
                visittime: { required: helpers.withMessage('Please select visit time', required) },
                karyakartasname: { required: helpers.withMessage('Please select name', required) },
            },
        };
    },
    mounted() {
        this.getvoterskaryakartas();
        this.getcountrycode();
        this.client_info = JSON.parse(localStorage.client_info);
        this.voterstorageimgpath = this.client_info.maa24;
    },
    methods: {
        getcountrycode(e) {
            this.ApiService.getcountrycode(e).then((data) => {
                if (data.status == 200) {
                    this.countryCodeList = data.data;
                }
            });
        },
        getvoterskaryakartas(e) {
            this.ApiService.getvoterskaryakartas(e).then((data) => {
                if (data.status == 200) {
                    this.karyakartasList = data.data;
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        searchvoterbtn(mobilenumber) {
            this.btnloader = true;
            this.ak24 = mobilenumber;
            this.ApiService.getUserUsingMobileNumber({ ak24: this.ak24 }).then((data) => {
                if (data.status == 200) {
                    this.searchMobileResult = data.data;
                    this.btnloader = false;
                } else {
                    this.searchMobileResult = null;
                    this.usersearchalertmodals = true;
                    this.btnloader = false;
                }
            });
        },
        closeSearchAlertModal() {
            this.usersearchalertmodals = false;
            this.searchmobile = '';
        },
        redirecttoaddvoter() {
            this.$router.push("/voters/addusingaadhar");
        },
        voterAddVisitModalOpen(e) {
            this.addvisitorModalOpen = true;
            this.add.visitreason = "";
            this.add.visitdate = null;
            this.add.visittime = "";
            this.add.karyakartasname = "";
            this.addRowId = e.ak1;
            this.add.primobile = e.ak24;
            this.add.fullname = e.ak5;
        },
        voterAddVisitModalClose() {
            this.addvisitorModalOpen = false;
        },
        CreateVisitDetailbtn() {
            this.addvoterregloader = true;
            this.v$.add.$validate();
            let fields = {};
            fields["ak1"] = this.addRowId;
            fields["ak29"] = this.add.visitreason;
            fields["ak29"] = this.add.visitdate;
            fields["ak29"] = this.add.visittime;
            fields["ak29"] = this.add.karyakartasname;
            if (!this.v$.add.$error) {
                console.log("Success");
                this.addvoterregloader = false;
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
    },
}
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.form-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 25px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    width: 115px;
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.voter-alert-group {
    font-family: "AcuminPro-Regular";
    font-size: 16px;
    letter-spacing: 1px;
    color: #303336;
}

.alert-body-bg {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.voter-already-user-status {
    padding: 8px 16px;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    margin-bottom: 15px;
    color: #63957d;
    font-weight: 700;
    font-family: "AcuminPro-SemiBold";
}
</style>